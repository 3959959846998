import { NAME } from '../constants';

export const AREA_SELECTED = `${NAME}/AREA_SELECTED`;
export const DURATION_SELECTED = `${NAME}/DURATION_SELECTED`;
export const AUTH_DATA_RETRIEVED = `${NAME}/AUTH_DATA_RETRIEVED`;
export const PARKING_TYPE_SELECTED = `${NAME}/PARKING_TYPE_SELECTED`;
export const VEHICLE_SELECTED = `${NAME}/VEHICLE_SELECTED`;
export const PARKING_PURCHASED = `${NAME}/PARKING_PURCHASED`;
export const RESET_STORE = `${NAME}/RESET_STORE`;

export const areaSelected = (area) => ({
  payload: area,
  type: AREA_SELECTED,
});

/**
 * Creates an action to indicate the selection of a duration.
 *
 * @param {Object} options - The options for the rate selection.
 * @param {string} options.currency - The currency type of the rate.
 * @param {number} options.duration - The duration of the rate in milliseconds.
 * @param {Date} options.startTime - The start time of the rate.
 * @param {Date} options.endTime - The end time of the rate.
 * @param {number} options.selectedHour - The selected hour of the rate.
 * @param {number} options.selectedMinute - The selected minute of the rate.
 * @returns {Object} An action object representing the rate selection.
 */

export const durationSelected = ({
  currency,
  duration,
  startTime,
  endTime,
  selectedHour,
  selectedMinute,
  selectedDurationOption,
  selectedParkingType,
}) => ({
  payload: {
    currency,
    duration,
    endTime,
    selectedDurationOption,
    selectedHour,
    selectedMinute,
    selectedParkingType,
    startTime,
  },
  type: DURATION_SELECTED,
});

export const parkingTypeSelected = ({ areaTypeId, areaNo, type }) => ({
  payload: {
    areaNo,
    areaTypeId,
    type,
  },
  type: PARKING_TYPE_SELECTED,
});

export const resetStore = () => ({
  type: RESET_STORE,
});

export const vehicleSelected = ({ country, lpn }) => ({
  payload: {
    country,
    lpn,
  },
  type: VEHICLE_SELECTED,
});

export const parkingPurchased = ({ parking, receipt }) => ({
  payload: { parking, receipt },
  type: PARKING_PURCHASED,
});
