import styled from 'styled-components';

export const ImageContainer = styled.div(() => ({
  lineHeight: 0,
}));

export const AreaButton = styled.div(({ theme }) => ({
  '&:focus': {
    backgroundColor: theme.colors.state('selected', {
      stroke: theme.colors.onSurface.primary,
    }),
    outline: 'none',
  },
  borderRadius: theme.radii.sm,
  marginBottom: theme.space.custom(1),
  marginTop: theme.space.custom(1),
  width: '100%',
}));
