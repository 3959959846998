import { combineReducers } from 'redux';
import * as actions from './actions';

const initialAreaState = {
  areaNo: null,
  selectedArea: null,
};

const initialDurationState = {
  currency: null,
  duration: null,
  endTime: null,
  selectedDurationOption: null,
  selectedHour: null,
  selectedMinute: null,
  selectedParkingType: null,
  startTime: null,
};

const initialVehicleState = {
  country: null,
  lpn: '',
};

const initialParkingTypeState = {
  areaNo: null,
  areaTypeId: null,
  type: null,
};

const initialParkingState = {
  parking: null,
  receipt: null,
};

const selectedArea = (state = initialAreaState, { payload, type }) => {
  if (type === actions.AREA_SELECTED) {
    return {
      areaNo: payload.areaNo,
      selectedArea: payload.selectedArea,
    };
  }
  if (type === actions.RESET_STORE) {
    return initialParkingState;
  }
  return state;
};

const selectedDuration = (state = initialDurationState, { payload, type }) => {
  if (type === actions.DURATION_SELECTED) {
    return {
      currency: payload.currency,
      duration: payload.duration,
      endTime: payload.endTime,
      selectedDurationOption: payload.selectedDurationOption,
      selectedHour: payload.selectedHour,
      selectedMinute: payload.selectedMinute,
      selectedParkingType: payload.selectedParkingType,
      startTime: payload.startTime,
    };
  }
  if (type === actions.RESET_STORE) {
    return initialParkingState;
  }
  return state;
};

const selectedParkingType = (
  state = initialParkingTypeState,
  { payload, type }
) => {
  if (type === actions.PARKING_TYPE_SELECTED) {
    return {
      areaNo: payload.areaNo,
      areaTypeId: payload.areaTypeId,
      type: payload.type,
    };
  }
  if (type === actions.RESET_STORE) {
    return initialParkingState;
  }
  return state;
};

const selectedVehicle = (state = initialVehicleState, { payload, type }) => {
  if (type === actions.VEHICLE_SELECTED) {
    return {
      country: payload.country,
      lpn: payload.lpn,
    };
  }
  if (type === actions.RESET_STORE) {
    return initialParkingState;
  }
  return state;
};

const purchasedParking = (state = initialParkingState, { payload, type }) => {
  if (type === actions.PARKING_PURCHASED) {
    return {
      parking: payload.parking,
      receipt: payload.receipt,
    };
  }
  if (type === actions.RESET_STORE) {
    return initialParkingState;
  }
  return state;
};

export const reducer = combineReducers({
  purchasedParking,
  selectedArea,
  selectedDuration,
  selectedParkingType,
  selectedVehicle,
});
