export function getParkingTypeModalString(key) {
  const mappings = {
    EXT_TIME: 'normalTime',
    NORMAL_BUCKET: 'normalBucket',
    NORMAL_TIME: 'normalTime',
    PERSONNEL_BUCKET: 'personnelBucket',
    PERSONNEL_TIME: 'personnelTime',
    RESIDENTIAL_BUCKET: 'residentialBucket',
    RESIDENTIAL_TIME: 'residentialTime',
  };

  if (mappings[key]) {
    return `global:startView:parkingTypeModal:${mappings[key]}`;
  }
  return '';
}
