import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Area } from '@/api/easypark/entities/areas/models';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import { getSelectedArea, resetStore } from '../../store';
import { getInputInterfaceType } from '../../helpers';

export function useStartView() {
  const dispatch = useDispatch();
  const areaDetails = useSelector(getSelectedArea);
  const flags = useSelector(getAllFlags);
  const router = useRouter();
  const inputInterfaceType = getInputInterfaceType(flags, areaDetails);

  const hasMultipleParkingTypes =
    Area.getParkingTypesByInputInterface(
      areaDetails?.selectedArea,
      inputInterfaceType
    )?.length > 1;

  const isMultipleParkingTypeEnabled =
    FeatureFlags.isEasyParkMultiChoiceMultiTypeParkingEnabled(flags);

  const showAreaTypeOptions =
    isMultipleParkingTypeEnabled &&
    (Boolean(
      Area.getMultipleChoiceOptions(areaDetails?.selectedArea)?.length > 0
    ) ||
      hasMultipleParkingTypes);

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (url === '/global/start') {
        dispatch(resetStore());
      }
    };

    // Trigger resetStore on initial mount
    dispatch(resetStore());

    // Listen to route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      // Clean up the event listener on component unmount
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    {
      showAreaTypeOptions,
    },
    {},
  ];
}
