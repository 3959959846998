/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getCurrentPosition } from '@/lib/shared/helpers';
import { useMutation, useQuery } from 'react-query';
import { Area } from '@/api/easypark/entities/areas/models';
import { Parking } from '@/api/easypark/entities/parkings/models';
import { useDispatch, useSelector } from 'react-redux';
import { getAppConfig } from '@/lib/shared/store';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import { useTranslation } from 'react-i18next';
import { useOnce } from '@parkmobile/ui';
import { getLink } from '@/lib/routing/helpers';
import {
  areaSelected,
  parkingTypeSelected,
} from '@/views/global-onstreet-parking/store';
import {
  isCountryAreaCodeSupported,
  getInputInterfaceType,
} from '@/views/global-onstreet-parking/helpers';
import {
  trackStartPageContinue,
  trackStartPageViewed,
} from '@/views/global-onstreet-parking/analytics';

const normalizeQueryParams = (query) => {
  return Object.keys(query).reduce((acc, key) => {
    const normalizedKey = key.toLowerCase();
    acc[normalizedKey] = query[key];
    return acc;
  }, {});
};

export function useStartView({ apis }) {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const appConfig = useSelector(getAppConfig);
  const flags = useSelector(getAllFlags);

  const { query } = router;
  const normalizedQuery = normalizeQueryParams(query);
  // Extract the areaId or areaid
  const areaIdQueryParam = normalizedQuery.areaid;

  const globalHeroImageSrc = _.get(appConfig, 'parking_flow_hero.url');
  const supportedCountries = _.get(
    FeatureFlags.easyParkWhitelist(flags),
    'countries'
  );
  const supportedParkingTypes = _.get(
    FeatureFlags.parkingTypeWhitelist(flags),
    'parkingTypes'
  );
  const isMultipleParkingTypeEnabled =
    FeatureFlags.isEasyParkMultiChoiceMultiTypeParkingEnabled(flags);

  const [isSelectingArea, setIsSelectingArea] = useState(false);
  const [activeParkingAreaId, setActiveParkingAreaId] = useState(null);
  const [activeAreaNo, setActiveAreaNo] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    isShowingAreaResidentialParkingModal,
    setIsShowingAreaResidentialParkingModal,
  ] = useState(false);

  // requests
  // Retrieve Area by Area Id
  const areaRequestQueryKey = [
    apis.areas.fetchAreaByAreaId,
    {
      id: areaIdQueryParam,
    },
  ];

  const areaRequestQueryFn = () =>
    apis.areas.fetchAreaByAreaId({
      id: areaIdQueryParam,
    });

  const areaRequest = useQuery(areaRequestQueryKey, areaRequestQueryFn, {
    enabled: Boolean(areaIdQueryParam),
    staleTime: 0,
  });

  const { mutateAsync: fetchPosition, data: position } =
    useMutation(getCurrentPosition);
  const { mutateAsync: listAreaNumbers, data: areas } = useMutation(
    apis.areas.fetchAreasByAreaNo
  );

  // forms
  const areaNoForm = useForm({ reValidateMode: 'onSubmit' });

  // shared data
  const hasConfirmedArea =
    Boolean(activeAreaNo) && Boolean(activeParkingAreaId);

  const selectedArea = (() => {
    const areaRequestData = _.get(areaRequest, 'data');
    if (!activeParkingAreaId || (!areas && !areaRequestData)) {
      return null;
    }
    return (
      _.find(areas, (area) => Area.getId(area) === activeParkingAreaId) ||
      areaRequestData
    );
  })();

  useEffect(() => {
    const retrievedAreaNo = _.get(areaRequest, 'data.areaNo');
    if (retrievedAreaNo) {
      setActiveAreaNo(retrievedAreaNo);
      setActiveParkingAreaId(areaIdQueryParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaRequest.data]);

  // reset form state if user decides to enter new area number
  const onAreaNoChange = (e) => {
    const { value } = e.target;
    if (hasConfirmedArea && activeAreaNo !== value) {
      setActiveParkingAreaId(null);
      setActiveAreaNo(null);
    }
    setActiveAreaNo(value);
  };

  /**
   * onSelectArea acts as a mini-form (modal) when the user deny's location
   * or attempts to reselect a area. Because of this, we will set/reset important
   * status' such as error and loading. We will also immediately close the modal
   * so that the loading/error states are handled by the main view.
   */
  const onSelectArea = useCallback((areaId) => {
    setIsLoading(true);
    setRequestError(null);
    setIsSelectingArea(false);
    try {
      setActiveParkingAreaId(areaId);
    } catch {
      setActiveParkingAreaId(null);
      setRequestError(t('global:startView:errors.technicalDifficulties'));
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onViewAreaSelections = useCallback(() => setIsSelectingArea(true), []);
  const onHideAreaSelections = useCallback(() => setIsSelectingArea(false), []);

  /**
   * onSelectAreaNo acts as the main area selection form.
   * It handles fetching the users location, fetching the list of areas, and
   * will either auto select an appropriate area or prompt the user to select.
   * Form errors and request errors are handled here along with loading state when
   * user starts submission.
   */
  const onSelectAreaNo = areaNoForm.handleSubmit(async ({ areaNo }, event) => {
    const areaCode = _.trim(areaNo);
    try {
      // clear previous errors
      setRequestError(null);
      // set loading state
      setIsLoading(true);

      // request position and fetch areas
      const currPosition = await fetchPosition();

      const currAreas = await listAreaNumbers({
        areaNo: areaCode,
        position: currPosition,
      });
      // if no areas were found, show form error
      if (currAreas.length === 0) {
        areaNoForm.setError(
          'areaNo',
          'invalid',
          t('global:startView:errors.areaNotFound')
        );
        return;
      }

      // if we have the users position or there is only one zone, autoselect it
      if (currPosition || currAreas.length === 1) {
        const closestArea = _.head(currAreas);

        const id = Area.getId(closestArea);
        setActiveParkingAreaId(id);
        setActiveAreaNo(areaCode);
        return;
      }

      setActiveAreaNo(areaCode);

      // else prompt for area selection
      setIsSelectingArea(true);
    } catch (e) {
      // if any requests fail they will be caught here and handled globally
      setRequestError(t('global:startView:errors.technicalDifficulties'));
    } finally {
      // cleanup by setting loading state to false
      setIsLoading(false);
    }
  });

  const handleAreaResidentialParkingModalOpen = () => {
    setIsShowingAreaResidentialParkingModal(true);
  };

  const handleAreaResidentialParkingModalClose = () => {
    setIsShowingAreaResidentialParkingModal(false);
  };

  const onConfirmArea = () => {
    const inputInterfaceType = getInputInterfaceType(flags, selectedArea);
    const parkingTypes = Area.getParkingTypesByInputInterface(
      selectedArea,
      inputInterfaceType
    );
    const hasMultipleParkingTypes = parkingTypes.length > 1;
    const isMultiParkingOptionArea =
      selectedArea?.multipleChoiceArea || hasMultipleParkingTypes;

    setIsLoading(true);

    dispatch(
      areaSelected({
        areaNo: activeAreaNo,
        selectedArea,
      })
    );

    if (!isMultiParkingOptionArea) {
      dispatch(
        parkingTypeSelected({
          areaNo: selectedArea?.areaNo,
          areaTypeId: selectedArea?.id,
          type: _.first(parkingTypes),
        })
      );
      router.push(..._.values(getLink('/global/duration')));
    }
  };

  const onPerformAreaValidations = () => {
    const isMultipleChoiceArea = Area.getIsMultipleChoiceArea(selectedArea);
    const hasParkingSpotNumbers = Area.getHasParkingSpotNumbers(selectedArea);
    const isAreaTypeSupported = Area.getIsAreaTypeSupported(selectedArea);
    const inputInterfaceType = getInputInterfaceType(flags, selectedArea);
    const isParkingTypeSupported =
      Area.getIsParkingTypeSupported(
        selectedArea,
        inputInterfaceType,
        supportedParkingTypes
      ) || isMultipleChoiceArea;
    const isAreaCountryCodeSupported = isCountryAreaCodeSupported(
      selectedArea,
      supportedCountries
    );
    const parkingTypes = Area.getParkingTypesByInputInterface(
      selectedArea,
      inputInterfaceType
    );
    const hasMultipleParkingTypes = parkingTypes.length > 1;

    const isMultiParkingOptionArea =
      selectedArea?.multipleChoiceArea || hasMultipleParkingTypes;

    const isSupported =
      isAreaTypeSupported &&
      isParkingTypeSupported &&
      !hasParkingSpotNumbers &&
      isAreaCountryCodeSupported;

    const isParkingAllowed = Area.getIsParkingAllowed(selectedArea);

    trackStartPageContinue({
      area: selectedArea,
      enabledLocationServices: Boolean(position),
      isAreaSupported: isSupported,
      parkingType: _.first(parkingTypes),
    });

    if (
      !isSupported ||
      (isMultiParkingOptionArea && !isMultipleParkingTypeEnabled)
    ) {
      // eslint-disable-next-line no-alert
      alert(t('global:startView:errors.areaNotSupported'));
      return;
    }

    if (!isParkingAllowed) {
      // eslint-disable-next-line no-alert
      alert(t('global:startView:errors.areaNotAllowed'));
      return;
    }

    // Residential parking validations
    const areaParkingType = Area.getUniqueParkingTypeArea(
      selectedArea,
      inputInterfaceType
    );
    const isAreaResidentialParkingOnly =
      Parking.getIsResidentialParking(areaParkingType);

    if (isAreaResidentialParkingOnly) {
      handleAreaResidentialParkingModalOpen();
      return;
    }

    // init parking flow
    onConfirmArea();
  };

  // form refs
  const refs = {
    areaNo: areaNoForm.register({
      required: t('global:startView:errors.areaCodeRequired'),
    }),
  };

  useOnce(() => {
    trackStartPageViewed();
  });

  return [
    {
      activeAreaNo,
      activeParkingAreaId,
      areaNoError: _.get(areaNoForm, 'errors.areaNo.message'),
      areas: areas || [_.get(areaRequest, 'data')] || [],
      globalHeroImageSrc,
      hasConfirmedArea,
      isLoading: isLoading || _.get(areaRequest, 'isLoading'),
      isSelectingArea,
      isShowingAreaResidentialParkingModal,
      refs,
      requestError,
      selectedArea,
    },
    {
      handleAreaResidentialParkingModalClose,
      onAreaNoChange,
      onConfirmArea,
      onHideAreaSelections,
      onPerformAreaValidations,
      onSelectArea,
      onSelectAreaNo,
      onViewAreaSelections,
    },
  ];
}
