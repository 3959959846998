import styled from 'styled-components';

export const Row = styled.div(({ theme, isSelected }) => ({
  '&:hover': {
    backgroundColor: isSelected ? theme.colors.brand : '#f5f5f5',
  },
  alignItems: 'center',
  backgroundColor: isSelected ? theme.colors.brand : 'white',
  borderBottom: theme.borders.thin,
  color: isSelected ? 'white' : 'black',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.space.md,
}));
