import _ from 'lodash';
import { createSelector } from 'reselect';
import { NAME } from '../constants';

export const getSelectedArea = (state) => _.get(state, [NAME, 'selectedArea']);
export const getSelectedDuration = (state) =>
  _.get(state, [NAME, 'selectedDuration']);
export const getSelectedParkingType = (state) =>
  _.get(state, [NAME, 'selectedParkingType']);
export const getSelectedVehicle = (state) =>
  _.get(state, [NAME, 'selectedVehicle']);
export const getPurchasedParking = (state) =>
  _.get(state, [NAME, 'purchasedParking']);

export const getCheckoutCompletionState = createSelector(
  getSelectedArea,
  getSelectedDuration,
  getSelectedParkingType,
  getSelectedVehicle,
  (selectedArea, selectedDuration, selectedParkingType, selectedVehicle) => {
    const isMultipleChoiceArea = Boolean(
      selectedArea?.selectedArea?.multipleChoiceArea
    );
    const isStartStepCompleted = isMultipleChoiceArea
      ? selectedParkingType?.type && selectedArea?.areaNo
      : Boolean(selectedArea?.areaNo);
    const isVehicleStepCompleted = Boolean(selectedVehicle?.lpn);
    const isDurationStepCompleted = Boolean(
      selectedDuration?.endTime || !_.isNull(selectedDuration.duration) // allows duration to be 0
    );

    return {
      duration: isDurationStepCompleted,
      start: isStartStepCompleted,
      vehicle: isVehicleStepCompleted,
    };
  }
);
