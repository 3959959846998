import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useQueries } from 'react-query';
import { useRouter } from 'next/router';
import { Area } from '@/api/easypark/entities/areas/models';
import { getLink } from '@/lib/routing/helpers';
import {
  getSelectedArea,
  parkingTypeSelected,
} from '@/views/global-onstreet-parking/store';
import { FeatureFlags } from '@/lib/feature-flags/models';
import { getAllFlags } from '@/lib/feature-flags/store';
import { PARKING_SUB_TYPES_HUMAN_READABLE } from '@/api/easypark/entities/parkings/constants';
import { getInputInterfaceType } from '@/views/global-onstreet-parking/helpers';
import { useTranslation } from 'react-i18next';
import { Parking } from '@/api/easypark/entities/parkings/models';
import { getParkingTypeModalString } from '../../helpers/getParkingTypeModalString';

export function useAreaTypeForm({ apis }) {
  // Hooks
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const flags = useSelector(getAllFlags);
  const areaDetails = useSelector(getSelectedArea);
  const inputInterfaceType = getInputInterfaceType(flags, areaDetails);

  const parkingTypes = Area.getParkingTypesByInputInterface(
    areaDetails?.selectedArea,
    inputInterfaceType
  );
  const hasMultipleParkingTypes = parkingTypes?.length > 1;
  const multipleChoiceDetails = Area?.getMultipleChoiceOptions(
    areaDetails?.selectedArea
  );

  // States
  const [selectedAreaTypeId, setSelectedAreaTypeId] = useState(null);
  const [selectedParkingType, setParkingType] = useState(null);
  const [isGetAreaByIdError, setIsGetAreaByIdError] = useState(false);
  const [showResidentialParkingModal, setShowResidentialParkingModal] =
    useState(false);

  const supportedParkingTypes = _.get(
    FeatureFlags.parkingTypeWhitelist(flags),
    'parkingTypes'
  );

  // Ensure multipleChoiceDetails is an array using lodash
  const retrieveAreaDetailsById = _.map(multipleChoiceDetails, (option) => ({
    onError: () => {
      setIsGetAreaByIdError(true);
    },
    queryFn: () =>
      apis.areas.fetchAreaDetailsById({ id: option.parkingAreaId }),
    queryKey: [apis.areas.fetchAreaDetailsById.queryKey, option.parkingAreaId],
  }));

  // Ensure useQueries is always called, even if it's with an empty array
  const detailsOfAreas = useQueries(
    retrieveAreaDetailsById?.length ? retrieveAreaDetailsById : []
  );

  // Combine multipleChoiceDetails with detailsOfAreas, filtering for active options
  const activeOptions = multipleChoiceDetails?.reduce((acc, option, index) => {
    if (!isGetAreaByIdError) {
      const result = detailsOfAreas[index];
      if (result?.isSuccess) {
        const combinedObject = { ...option, ...result.data };
        if (combinedObject.status === 'ACTIVE') {
          acc.push(combinedObject);
        }
      }
    }
    return acc;
  }, []);

  // Create queries for areas by areaNo
  const retrieveAreaDetailsByAreaNoQueries = activeOptions?.reduce(
    (acc, option) => {
      if (option.areaNo) {
        acc.push({
          enabled: true,
          queryFn: () =>
            apis.areas.fetchAreasByAreaNo({ areaNo: option.areaNo }),
          queryKey: [apis.areas.fetchAreasByAreaNo.queryKey, option.areaNo],
        });
      } else {
        acc.push({
          enabled: false,
          queryFn: () => Promise.resolve(),
          queryKey: ['dummy-key'],
        });
      }
      return acc;
    },
    []
  );

  // Ensure useQueries is always called, even if it's with an empty array
  const retrieveAreaDetailsByAreaNo = useQueries(
    retrieveAreaDetailsByAreaNoQueries?.length
      ? retrieveAreaDetailsByAreaNoQueries
      : []
  );

  // Combine area details for multiple choice options
  const combinedAreaDetailsForMultipleChoice = activeOptions?.map(
    (option, index) => {
      const query = retrieveAreaDetailsByAreaNo[index];
      if (query?.isSuccess) {
        // Find the parking details for the current option's parkingAreaId
        const areaNoParkingDetails = query.data?.find(
          (area) => area.id === option.parkingAreaId
        );
        const isParkingTypeSupported = Area.getIsParkingTypeSupported(
          areaNoParkingDetails,
          inputInterfaceType,
          supportedParkingTypes
        );

        if (areaNoParkingDetails && isParkingTypeSupported) {
          return _.merge({}, option, areaNoParkingDetails);
        }
      }

      // If query is not successful or parking type is not supported, return the original option
      return option;
    }
  );

  // Combine all area details into a single object or array as needed
  const combinedAreaParkingDetails = retrieveAreaDetailsByAreaNo
    ?.filter((query) => query?.isSuccess)
    ?.map((query) => query.data)
    ?.reduce((acc, details) => {
      // Assuming details is an array and you want to merge all details into a single array
      return [...acc, ...details];
    }, []);

  const parkingInterfaceTypeOptions = useMemo(
    () =>
      _.reduce(
        parkingTypes,
        (acc, key) => {
          if (_.includes(supportedParkingTypes, key)) {
            acc.push({
              name:
                t(getParkingTypeModalString(key)) ||
                PARKING_SUB_TYPES_HUMAN_READABLE[key],
              type: key,
            });
          }
          return acc;
        },
        []
      ),
    [parkingTypes, supportedParkingTypes, t]
  );

  const handleOpenResidentialParkingModal = () => {
    setShowResidentialParkingModal(true);
  };
  const handleCloseResidentialParkingModal = () => {
    setShowResidentialParkingModal(false);
  };

  const handleSelectAreaType = () => {
    const selectedAreaDetailsOfType =
      combinedAreaDetailsForMultipleChoice?.find(
        (areaDetail) => areaDetail?.id === selectedAreaTypeId
      );

    const selectedParkingAreaInfo = combinedAreaParkingDetails?.find(
      (areaInfo) => areaInfo?.id === selectedAreaTypeId
    );

    // Retrieve areaNo Info to get parking type to populate duration page
    // Dispatch to parkingType and area no info
    if (selectedAreaTypeId) {
      dispatch(
        parkingTypeSelected({
          areaNo: selectedAreaDetailsOfType?.areaNo,
          areaTypeId: selectedAreaTypeId,
          type: _.first(
            Area.getParkingTypesByInputInterface(selectedParkingAreaInfo)
          ),
        })
      );
    } else if (selectedParkingType) {
      dispatch(
        parkingTypeSelected({
          areaNo: areaDetails?.areaNo,
          areaTypeId: areaDetails?.id,
          type: selectedParkingType,
        })
      );
    }

    // reroute user to duration view
    router.push(..._.values(getLink('/global/duration')));
  };

  const handleGoBack = () => {
    router.push(..._.values(getLink('/global/start')));
  };

  const handleConfirmAreaType = () => {
    const areaParkingType = Area.getUniqueParkingTypeArea(
      areaDetails?.selectedArea,
      inputInterfaceType
    );
    const isResidentialParking = Parking.getIsResidentialParking(
      selectedParkingType || areaParkingType
    );
    if (isResidentialParking) {
      handleOpenResidentialParkingModal();
      return;
    }
    handleSelectAreaType();
  };

  const handleMultipleChoicesOptionClick = (area) => {
    setSelectedAreaTypeId(area);
  };

  const handleParkingTypeOptionClick = (parkingType) => {
    setParkingType(parkingType);
  };

  const handleRequestRetry = () => {
    setIsGetAreaByIdError(false);
    detailsOfAreas.forEach((query) => query.refetch());
  };

  return [
    {
      hasMultipleParkingTypes,
      isGetAreaByIdError,
      isLoading: detailsOfAreas?.some((result) => result.isLoading),
      multipleChoiceAreaTypes: combinedAreaDetailsForMultipleChoice,
      parkingInterfaceTypeOptions,
      selectedAreaType: selectedAreaTypeId,
      selectedParkingType,
      showResidentialParkingModal,
    },
    {
      handleCloseResidentialParkingModal,
      handleConfirmAreaType,
      handleGoBack,
      handleMultipleChoicesOptionClick,
      handleParkingTypeOptionClick,
      handleRequestRetry,
      handleSelectAreaType,
    },
  ];
}
