import {
  Box,
  Button,
  hideIf,
  Notification,
  Space,
  Text,
  Spinner,
  showIf,
  ChevronRightIcon,
} from '@parkmobile/ui';
import NextLink from 'next/link';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as areasAPI from '@/api/easypark/entities/areas/api';
import { useAreaTypeForm } from './area-type-form-hook';
import * as styles from './area-type-form.styled';

import { AreaResidentialParkingModal } from '../area-residential-parking-modal/area-residential-parking-modal';

AreaTypeFormView.propTypes = {
  apis: PropTypes.shape({
    areas: PropTypes.object,
  }),
};

AreaTypeFormView.defaultProps = {
  apis: {
    areas: areasAPI,
  },
};

export function AreaTypeFormView({ apis }) {
  const { t } = useTranslation();
  const [data, handlers] = useAreaTypeForm({ apis });

  const {
    hasMultipleParkingTypes,
    isGetAreaByIdError,
    isLoading,
    multipleChoiceAreaTypes,
    parkingInterfaceTypeOptions,
    selectedAreaType,
    selectedParkingType,
    showResidentialParkingModal,
  } = data;

  const {
    handleMultipleChoicesOptionClick,
    handleParkingTypeOptionClick,
    handleSelectAreaType,
    handleGoBack,
    handleRequestRetry,
    handleConfirmAreaType,
    handleCloseResidentialParkingModal,
  } = handlers;

  return (
    <>
      <Box maxWidth='512px' width='100%'>
        <Space height='xl' />
        <Text as='h1' variant='h1'>
          {t('global:startView:secondaryHeader')}
        </Text>
        <Space height='xl' />
        {showIf(isLoading)(() => {
          <Spinner />;
        })}

        {showIf(isGetAreaByIdError)(() => (
          <>
            <Notification variant='error'>
              <Text as='h2'>
                {t('global:startView:errors:multiChoiceError:body')}
              </Text>
            </Notification>
            <Space height='lg' />
            <Box
              display='grid'
              gridGap='md'
              gridTemplateColumns='1fr 1fr'
              width='100%'
            >
              <NextLink passHref={true} href={'/global/start'}>
                <Button
                  color='neutral'
                  data-pmtest-id='go-back-button'
                  variant='secondary'
                >
                  {t('global:startView:errors:multiChoiceError.goBackButton')}
                </Button>
              </NextLink>
              <Button
                color='neutral'
                data-pmtest-id='retry-button'
                onClick={handleRequestRetry}
              >
                {t('global:startView:errors:multiChoiceError.retryButton')}
              </Button>
            </Box>
          </>
        ))}

        {multipleChoiceAreaTypes?.map((option) => (
          <styles.Row
            key={option.name}
            onClick={() =>
              handleMultipleChoicesOptionClick(option.parkingAreaId)
            }
            isSelected={option.parkingAreaId === selectedAreaType}
          >
            {option.name}
            <ChevronRightIcon />
          </styles.Row>
        ))}
        <Space height='xxl' />
        {showIf(hasMultipleParkingTypes)(() => {
          return (
            <>
              {parkingInterfaceTypeOptions?.map((option, key) => (
                <styles.Row
                  key={`${option.type}-${key}`}
                  onClick={() => handleParkingTypeOptionClick(option.type)}
                  isSelected={option.type === selectedParkingType}
                >
                  {option.name}
                  <ChevronRightIcon />
                </styles.Row>
              ))}
              <Space height='xxl' />
            </>
          );
        })}
        {hideIf(isGetAreaByIdError)(() => (
          <Button
            color='neutral'
            data-pmtest-id='select-area-type-button'
            disabled={!selectedAreaType && !selectedParkingType}
            fullWidth={true}
            loading={isLoading}
            onClick={handleConfirmAreaType}
          >
            {t('global:startView:cta.continue')}
          </Button>
        ))}
      </Box>
      <AreaResidentialParkingModal
        isOpen={showResidentialParkingModal}
        onConfirm={handleSelectAreaType}
        onBack={handleGoBack}
        onClose={handleCloseResidentialParkingModal}
      />
    </>
  );
}
