import { Container, Space, showIf, hideIf } from '@parkmobile/ui';
import React from 'react';
import { AreaSelectorFormView } from './components/area-selector-form/area-selector-form-view';
import { AreaTypeFormView } from './components/area-type-form/area-type-form-view';
import { useStartView } from './start-view-hooks';

export function StartView() {
  const [data] = useStartView();
  const { showAreaTypeOptions } = data;
  return (
    <Container
      alignItems='center'
      backgroundColor='surface'
      disableMaxWidth={true}
      display='flex'
      flex='1 1 auto'
      flexDirection='column'
      py='md'
    >
      {hideIf(showAreaTypeOptions)(() => (
        <AreaSelectorFormView />
      ))}
      {showIf(showAreaTypeOptions)(() => (
        <AreaTypeFormView />
      ))}
      <Space height={{ _: 'xxl', sm: '*9' }} />
    </Container>
  );
}
