import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Text, Button } from '@parkmobile/ui';

import { Modal } from '@/lib/shared/components';

AreaResidentialParkingModal.propTypes = {
  isFullScreen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export function AreaResidentialParkingModal({
  isFullScreen,
  isOpen,
  onBack,
  onClose,
  onConfirm,
}) {
  const { t } = useTranslation();

  const content = (
    <Box>
      <Box mb='lg'>
        <Text>{t('global:startView:residentialParkingModal:description')}</Text>
      </Box>
      <Box display='grid' gridGap='md' gridTemplateColumns='1fr 1fr'>
        <Button
          color='neutral'
          variant='secondary'
          data-pmtest-id='residential-parking-back-button'
          onClick={onBack}
        >
          {t('global:startView:residentialParkingModal:button-back')}
        </Button>
        <Button
          color='neutral'
          data-pmtest-id='residential-parking-confirm-button'
          onClick={onConfirm}
        >
          {t('global:startView:residentialParkingModal:button-confirm')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      content={content}
      isFullScreen={isFullScreen}
      isOpen={isOpen}
      onClose={onClose}
      title={t(`global:startView:residentialParkingModal:title`)}
    />
  );
}
