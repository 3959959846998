import {
  Box,
  Button,
  FormControl,
  Input,
  Notification,
  Space,
  Stack,
  Text,
  useTheme,
} from '@parkmobile/ui';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { showIf } from 'react-render-helpers';
import * as areasAPI from '@/api/easypark/entities/areas/api';
import { Icon, Modal, Image } from '@/lib/shared/components';
import { Area } from '@/api/easypark/entities/areas/models';
import { useTranslation } from 'react-i18next';
import { countries } from 'countries-list';
import { useStartView } from './area-selector-form-hook';
import * as styles from './area-selector-form.styled';
import { AreaResidentialParkingModal } from '../area-residential-parking-modal/area-residential-parking-modal';

AreaSelectorFormView.propTypes = {
  apis: PropTypes.shape({
    areas: PropTypes.object,
  }),
};

AreaSelectorFormView.defaultProps = {
  apis: {
    areas: areasAPI,
  },
};

export function AreaSelectorFormView({ apis }) {
  const theme = useTheme();
  const [data, handlers] = useStartView({ apis });
  const { t } = useTranslation();

  const getCountryFlag = (countryCode) => countries[countryCode]?.emoji || null;

  const {
    activeAreaNo,
    areas,
    areaNoError,
    globalHeroImageSrc,
    hasConfirmedArea,
    isShowingAreaResidentialParkingModal,
    isLoading,
    isSelectingArea,
    refs,
    requestError,
    selectedArea,
  } = data;

  return (
    <>
      {showIf(isSelectingArea)(() => (
        <Modal
          content={
            <Box overflow='auto' py='sm'>
              <Stack dividers={true} role='list' space='xs'>
                {_.map(areas, (area) => {
                  const parkingType = _.startCase(Area.getAreaType(area));
                  const code = Area.getAreaNumber(area);
                  const id = Area.getId(area);

                  const handleSelect = () => handlers.onSelectArea(id);

                  return (
                    <Box key={id} paddingX='xs' role='listitem'>
                      <styles.AreaButton
                        as='button'
                        data-pmtest-id={`choose-area-${id}`}
                        onClick={handleSelect}
                      >
                        <Box
                          alignItems='center'
                          backgroundColor='transparent'
                          display='flex'
                          px={theme.space.custom(1.5)}
                        >
                          <Box
                            backgroundColor='transparent'
                            height='18px'
                            width='18px'
                          >
                            <Icon
                              iconName='marker'
                              size='auto'
                              variant='primary'
                            />
                          </Box>
                          <Space width='sm' />
                          <Text weight='boldest'>{code}</Text>
                          <Space width='sm' />
                          <Text>|</Text>
                          <Space width='sm' />
                          <Text
                            transform='capitalize'
                            truncate={true}
                            weight='boldest'
                          >
                            {Area.getAreaName(area)}
                          </Text>
                        </Box>
                        <Space height='sm' />
                        <Box
                          alignItems='center'
                          backgroundColor='transparent'
                          display='flex'
                          px={theme.space.custom(1.5)}
                        >
                          <Space width='lg' />
                          <Text truncate={true}>
                            {Area.getLocationName(area)}
                            &nbsp;{getCountryFlag(Area.getCountryCode(area))}
                          </Text>
                          {showIf(parkingType)(
                            <>
                              <Space width='sm' />
                              •
                              <Space width='sm' />
                              <Text>{parkingType}</Text>
                            </>
                          )}
                        </Box>
                      </styles.AreaButton>
                    </Box>
                  );
                })}
              </Stack>
            </Box>
          }
          contentLabel={t('global:startView:multiSelectModal.title')}
          isFullScreen={true}
          isOpen={isSelectingArea}
          onClose={handlers.onHideAreaSelections}
          showContentPadding={false}
          title={
            <Text as='span' variant='h2'>
              {t('global:startView:multiSelectModal.title')}
            </Text>
          }
        />
      ))}
      <Box maxWidth='512px' width='100%'>
        {/* Header Text and Image */}
        <Space height={{ _: 'xl', sm: '*9' }} />
        <styles.ImageContainer as={Box} lineHeight={0}>
          <Image
            fillContainer={true}
            imgSrc={globalHeroImageSrc}
            resize={{ width: '512' }}
            data-pmtest-id='global-hero-image'
          />
        </styles.ImageContainer>
        <Space height='xl' />
        <Text as='h1' variant='display3'>
          {t('global:startView:pageTitle')}
        </Text>

        {/* Request errors */}
        {showIf(Boolean(requestError))(() => (
          <>
            <Space height='xl' />
            <Notification variant='error'>
              <Text>{requestError}</Text>
            </Notification>
          </>
        ))}

        {/* AreaNo Form */}
        <Space height='xl' />
        <form noValidate={true} onSubmit={handlers.onSelectAreaNo}>
          <FormControl
            error={areaNoError}
            label={t('global:startView:areaCode')}
          >
            <Input
              data-pmtest-id={'area-code-input'}
              endAdornment={
                hasConfirmedArea &&
                areas.length !== 0 && (
                  <Box alignItems='center' display='flex'>
                    <Button
                      color='brand'
                      data-pmtest-id='change-area'
                      icon={
                        <Box height='14px' width='14px'>
                          <Icon
                            iconName='marker'
                            size='auto'
                            variant='secondary'
                          />
                        </Box>
                      }
                      onClick={handlers.onViewAreaSelections}
                      variant='text'
                    >
                      <div
                        style={{
                          maxWidth: '125px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {`${Area.getAreaName(
                          selectedArea
                        )} | ${Area.getLocationName(selectedArea)}` ||
                          t('global:startView:cta.chooseLocation')}
                      </div>
                    </Button>
                  </Box>
                )
              }
              id='areaNo'
              inputMode='numeric'
              name='areaNo'
              onChange={handlers.onAreaNoChange}
              value={_.toString(activeAreaNo)}
              ref={refs.areaNo}
              type='text'
            />
          </FormControl>
          <Box
            data-pmtest-id='select-area-code-container'
            display={hasConfirmedArea ? 'none' : 'block'}
          >
            <Space height='lg' />
            <Button
              color='neutral'
              data-pmtest-id='select-area-code-button'
              disabled={hasConfirmedArea}
              fullWidth={true}
              loading={isLoading}
              type='submit'
            >
              {t('global:startView:cta.confirmArea')}
            </Button>
          </Box>
        </form>
        {/* handle navigation when no area options */}
        {showIf(hasConfirmedArea)(() => (
          <Box>
            <Space height='lg' />
            <Button
              color='neutral'
              data-pmtest-id='confirm-area-code-button'
              fullWidth={true}
              loading={isLoading}
              onClick={handlers.onPerformAreaValidations}
            >
              {t('global:startView:cta.continue')}
            </Button>
          </Box>
        ))}
      </Box>
      <AreaResidentialParkingModal
        isOpen={isShowingAreaResidentialParkingModal}
        onClose={handlers.handleAreaResidentialParkingModalClose}
        onBack={handlers.handleAreaResidentialParkingModalClose}
        onConfirm={handlers.onConfirmArea}
      />
    </>
  );
}
