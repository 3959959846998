import { fetch } from '@/lib/api/helpers';
import _ from 'lodash';
import { NAME, AREA_BUCKET_TYPES } from './constants';
import { sortAreasByDistance } from './helpers';

fetchAreasByAreaNo.queryKey = `${NAME}/fetchAreasByAreaNo`;
export async function fetchAreasByAreaNo(
  { position, areaNo },
  { raw = false } = {}
) {
  const parameters = new URLSearchParams({
    areaNo,
  });

  const result = await fetch({
    endpoint: `/global/areas?${parameters}`,
    method: 'GET',

    validateStatus(status) {
      return status >= 200 && status <= 300;
    },
  });

  if (raw) return result;

  const areas = _.isArray(result.data) ? result.data : [];
  return position ? sortAreasByDistance(position, areas) : areas;
}

fetchAreaByAreaId.queryKey = `${NAME}/fetchAreaById`;
export async function fetchAreaByAreaId({ id }) {
  // Sanitize input to ensure only valid numbers are processed
  const sanitizedId = Number(id);

  if (
    Number.isNaN(sanitizedId) || // Check if it's a number
    !Number.isInteger(sanitizedId) || // Check if it's an integer
    sanitizedId <= 0 // Check if it's non-negative and non-zero
  ) {
    throw new Error(
      'Invalid area ID. The ID must be a non-negative, non-zero integer.'
    );
  }

  const result = await fetch({
    endpoint: `/global/areas/parkingarea/${sanitizedId}`,
    method: 'GET',
    validateStatus(status) {
      return status >= 200 && status <= 300;
    },
  });

  const area = _.get(result, 'data');
  return area;
}

fetchAreaDetailsById.queryKey = `${NAME}/fetchAreaDetailsById`;
export async function fetchAreaDetailsById({ id }, { raw = false } = {}) {
  const result = await fetch({
    endpoint: `/global/areas/parkingareadetails/id/${id}`,
    method: 'GET',

    validateStatus(status) {
      return status >= 200 && status <= 300;
    },
  });

  const area = _.get(result, 'data');

  if (raw) return result;

  return area;
}

fetchAreaTariffUnits.queryKey = `${NAME}/fetchAreaTariffUnits`;
export async function fetchAreaTariffUnits(
  { areaNo, countryCode, type = AREA_BUCKET_TYPES.NORMAL_BUCKET },
  { raw = false } = {}
) {
  const result = await fetch({
    endpoint: `/global/areas/tariff-units?countryCode=${countryCode}&type=${type}&areaNo=${areaNo}`,
    method: 'GET',

    validateStatus(status) {
      return status >= 200 && status <= 300;
    },
  });

  const tariffUnits = _.get(result, 'data');

  if (raw) return result;

  return tariffUnits;
}
